<template>
  <div class="contact">
    <img-div
      class-name=""
      height="400"
      :img-url="require('@/assets/contact/mask.png')"
    />
    <img
      class="locationImg"
      src="../../assets/contact/locationimg.png"
      alt=""
    />
    <div class="container flex flex-jsb mt340">
      <div class="contact-list">
        <div class="flex flex-vc">
          <img src="../../assets/contact/phone.png" alt="" />
          <span v-html="$t('contact.phone')"></span>
        </div>
        <div class="flex flex-vc">
          <span class="img"></span>
          <span v-html="$t('contact.email')"></span>
        </div>
        <div class="flex flex-vc mt10">
          <span class="img"></span>
          <span v-html="$t('contact.fax')"></span>
        </div>
      </div>
      <div class="location">
        <div class="flex flex-vc">
          <img src="../../assets/contact/location.png" alt="" />
          <span>{{ $t("contact.location") }}</span>
        </div>
      </div>
      <div class="location">
        <div class="flex flex-vc">
          <img src="../../assets/contact/work_time.png" alt="" />
          <span>{{ $t("contact.workTime") }}</span>
        </div>
      </div>
      <div class="service" @click="goService">
        <div class="flex flex-vc">
          <img src="../../assets/contact/service.png" alt="" />
          <a>{{ $t("contact.onlineService") }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImgDiv from "@/components/base/ImgDiv.vue";
export default {
  components: {
    ImgDiv,
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    goService() {
      _MEIQIA("showPanel");
    },
  },
};
</script>
<style lang="scss" scoped>
.contact {
  background-color: var(--whiteColor);
  position: relative;
  padding-bottom: 100px;
  .mt340 {
    margin-top: 340px;
  }
  .contact-list,
  .location,
  .service {
    font-size: 14px;
    color: var(--blackColor);
    img,
    .img {
      width: 40px;
      margin-right: 16px;
    }
    img {
      height: 40px;
    }
    a {
      color: var(--blue);
    }
  }
  .service {
    cursor: pointer;
  }
  .mt10 {
    margin-top: 10px;
  }
  .locationImg {
    // margin: auto;
    width: 1180px;
    position: absolute;
    top: 156px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
