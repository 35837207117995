<template>
  <div
    class="img-div"
    :style="`background-image:url(${imgUrl});height:${height}px;`"
  >
    <!-- <el-image
      :style="`${height ? 'height:' + height + 'px;' : ''}`"
      :src="imgUrl"
      class="img-div_bgImg"
      :fit="height ? 'none' : fit"
    ></el-image> -->
    <!-- <img alt="" /> -->
    <div :class="['img-div_content', className]">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    className: {
      type: String,
      default: "",
    },
    imgUrl: {
      type: [String],
      default: "",
    },
    fit: {
      type: String,
      default: "scale-down",
    },
    height: {
      type: [String, Number],
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.img-div {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  // background-origin: content-box;
  &_bgImg {
    display: block;
    width: 100%;
  }
  &_content {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
